
import HeaderTit from '@/components/HeaderTit.vue'
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import AxiosAdapter from '@/request/AxiosAdapter'
export default defineComponent({
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const formData = reactive({
      caseId: route.query.medCaseId,
      caseUserId: route.query.medCaseUserId,
      outTradeNo: route.query.outTradeNo,
      amount: route.query.amount,
      appId: route.query.appId,
      subject: '测试'
    })
    //插入字符串
    const appendChild = (str: string) => {
      let div = document.querySelector('#payDiv')
      if (!div) {
        div = document.createElement('div')
        div.id = 'payDiv'
        document.body.appendChild(div)
      }
      div.innerHTML = str
    }
    // 用于转译html
    const escape = (str: string) => {
      str = '' + str
      return str
        .replace(/&lt;/g, '<')
        .replace(/&quot;/g, '"')
        .replace(/&amp;/g, '&')
        .replace(/&gt;/g, '>')
        .replace(/n;/g, '')
    }
    //调用接口获取str
    const getStr = async (data: object) => {
      await AxiosAdapter.post({
        url: '/alipay/createAliPay',
        data
      })
      .then((res:any) => {
        if(res as string){
          const str = escape(res)
          appendChild(str)
        }
      })
      .catch((error)=>{
          console.log(error)
      })
    }
    onMounted(() => {
        getStr(formData)
    })
    return {
      formData,
      getStr,
      escape,
      appendChild,
    }
  }
})
